import React from "react";
import ImageUpload from "./home.js"; // Make sure the path is correct

function App() {
  return (
    <div className="App">
      <ImageUpload />
    </div>
  );
}

export default App;
